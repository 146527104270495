<template>
  <wide-panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-toolbar card>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
      />
    </v-toolbar>
    <v-alert
      :value="alert"
      :type="alertType"
      transition="scale-transition"
    >
      {{ alertMessage }}
    </v-alert>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :search="search"
      item-key="name"
      class="elevation-1"
      disable-initial-sort
    >
      <template #items="props">
        <tr>
          <td class="text-xs-left">
            {{ props.item.name }}
          </td>
          <td class="text-xs-left">
            {{ props.item.school }}
          </td>
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td class="text-xs-left">
            {{ props.item.entry }}
          </td>
          <td class="text-xs-center">
            {{ props.item.plan }}
          </td>
          <td class="text-xs-left">
            {{ props.item.activation }}
          </td>
          <td class="text-xs-left">
            {{ props.item.status }}
          </td>
          <td class="text-xs-center">
            {{ props.item.whitelist }}
          </td>
          <td class="text-xs-left">
            {{ props.item.dealer }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </wide-panel>
</template>

<script>
import { createGetParams } from '@/rest'

const title = 'Student List'

export default {
  components: {
    WidePanel: () => import(/* webpackChunkName: "panel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      alert: false,
      alertMessage: null,
      alertType: 'error',
      breadcrumbs: [
        {
          text: 'Jom Bantu', disabled: false, to: '/operation/jom_bantu_kits',
        },
        {
          text: title, disabled: true,
        },
      ],
      items: [],
      headers: [
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name',
          width: '20%',
        },
        {
          text: 'School',
          align: 'left',
          sortable: true,
          value: 'school',
          width: '20%',
        },
        {
          text: 'Msisdn',
          align: 'left',
          sortable: true,
          value: 'msisdn',
        },
        {
          text: 'Entry',
          align: 'left',
          sortable: true,
          value: 'entry',
          width: '20%',
        },
        {
          text: 'Plan',
          align: 'left',
          sortable: true,
          value: 'plan',
        },
        {
          text: 'Activation',
          align: 'left',
          sortable: true,
          value: 'activation',
          width: '20%',
        },
        {
          text: 'Status',
          align: 'left',
          sortable: true,
          value: 'status',
        },
        {
          text: 'Whitelist',
          align: 'center',
          sortable: true,
          value: 'whitelist',
        },
        {
          text: 'Dealer',
          align: 'left',
          sortable: true,
          value: 'dealer',
        },
      ],
      loading: false,
      search: '',
      title: title,
    }
  },
  mounted: function () {
    this.getJomBantuListStudent()
  },
  methods: {
    getJomBantuListStudent: function () {
      this.loading = false
      this.alert = false
      this.$rest.get('getJomBantuListAdmin.php', createGetParams())
        .then(function (response) {
          this.items = response.data
        }.bind(this)).catch(error => {
          let message = error.message
          if (error.response) {
            message += ': ' + error.response.data
          }
          this.alertMessage = message
          this.alert = true
          this.loading = false
        })
    },
  },
}
</script>
